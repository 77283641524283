@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body{
  font-family: 'Montserrat', sans-serif;
}


.image-grid {
	--gap: 16px;
	--num-cols: 3;
	--row-height: 305px;

	box-sizing: border-box;

	display: grid;
	grid-template-columns: repeat(var(--num-cols), 1fr);
	grid-auto-rows: var(--row-height);
	gap: var(--gap);
}

.image-grid>img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.image-grid-col-2 {
	grid-column: span 1;
}

.image-grid-row-2 {
	grid-row: span 2;
}

/* Anything udner 1024px */
@media screen and (max-width: 1024px) {
	.image-grid {
		--num-cols: 2;
		--row-height: 200px;
	}
	.image-grid {
		--row-height: 250x;
	}
}